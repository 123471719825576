import {
  createMasterResponse,
  getMasterResponse,
} from './../../../store/Masters/masters-selector';
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import { process, State, toODataString } from '@progress/kendo-data-query';
import { mastersData } from './masters-data';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { RpCreateMasterComponent } from '../rp-create-master/rp-create-master.component';
import { LoaderService } from './../../../core/services/loader.service';
import { Store, select } from '@ngrx/store';
import { SharedService } from '../../../shared/shared.service';
import { Subscription } from 'rxjs';
import {
  GetAllmaster,
  Getmaster,
  deleteMaster,
} from '../../../store/Masters/masters.actions';
import { Master } from '../../../store/Masters/masters.models';
import {
  GetSuppliers,
  deleteSupplier,
  resetSupplierState,
} from '../../../store/Suppliers/suppliers.actions';
import {
  getAllSuppliersResponse,
  getSuppliersResponse,
} from '../../../store/Suppliers/suppliers-selector';

import { RpCreateSupplierComponent } from '../rp-create-supplier/rp-create-supplier.component';
import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { Injectable } from '@angular/core';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { getAllMasterResponse } from '../../../store/Masters/masters-selector';
import { GetAllSuppliers } from '../../../store/Suppliers/suppliers.actions';
import { table_pageSize } from '../../../shared/constants/templatesNames';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { resetState } from '../../../store/Masters/masters.actions';
@Component({
  selector: 'app-rp-masters',
  templateUrl: './rp-masters.component.html',
  styleUrl: './rp-masters.component.scss',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .k-grid .k-grid-content td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `,
  ],
})
export class RpMastersComponent {
  public filterMode: FilterableSettings = 'row';
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  mastersList: any = [];

  public mastergridData: GridDataResult = { data: [], total: 0 };
  selectableSettings = this.sharedService.selectableSettings;

  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  //public pageSizes = true;
  public pageSizes = table_pageSize.pageSizes;
  public supplierpageSizes = table_pageSize.pageSizes;
  public previousNext = true;

  public pageSize = 10;
  public skip = 0;
  public supplierskip = 0;
  public supplierPageSize = 10;
  totalMasterCount: number = 0;
  currentPage: number = 1;
  supplierCurrentPage: number = 1;
  modalRef!: BsModalRef;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  public masterstate: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  public supplierstate: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  @ViewChild('masterGrid') public masterhiddenGrid: GridComponent | undefined;
  @ViewChild('supplierGrid') public SupplierhiddenGrid:
    | GridComponent
    | undefined;
  allMastersList: any[] = [];
  allSupplierList: any[] = [];

  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  public sort: SortDescriptor[] = [];
  public suppliersgridData: GridDataResult = { data: [], total: 0 };
  suppliersList: any = [];
  totalSuppliersCount: number = 0;
  mySelectionMasters: any = [];
  mySelectionSupplier: any = [];
  queryString: string = '';
  orderByQuery: string = '';
  filterqueryString: string = '';
  supplierOrderByQuery: string = '';
  searchedMasterValue: string = '';
  searchedSupplierValue: string = '';
  breadCrumbItems: Array<{}> | undefined;
  constructor(
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private store: Store,
    private sharedService: SharedService,
    private PdfexportService: PdfexportService
  ) {
    this.breadCrumbItems = [{ label: 'Masters And Suppliers' }];

    this.getStoreInfo();
    this.selectableSettings = this.sharedService.selectableSettings;
  }

  protected masterPageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1; // Calculate current page
    this.getMastersData(); // Load data for the current page
  }

  masterSearchChange(ev: string): void {
    if (ev.length == 0) { 
      this.currentPage = 1;
      this.masterPageChange({ skip:0, take:this.pageSize })     
      this.getMastersData();
    }
  }

  supplierSearchChange(ev: string): void {
    if (ev.length == 0) {
      this.supplierPageChange({ skip:0, take:this.supplierPageSize })
      this.getSuppliersData();
    }
  }
  protected dataStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadMasters();
  }

  protected supplierdataStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadSuppliers();
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();
      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }

  private loadMasters(): void {
    const state: State = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };

    const processedData = process(this.mastersList, state);
    this.mastergridData = {
      data: processedData.data,
      total: this.totalMasterCount,
    };
  }

  private loadSuppliers(): void {
    const state: State = {
      // skip: this.skip,
      take: this.supplierPageSize,
      sort: this.sort,
      filter: this.filter,
    };

    const processedData = process(this.suppliersList, state);

    this.suppliersgridData = {
      data: processedData.data,
      total: this.totalSuppliersCount,
    };
    console.log('this.suppliersgridData', this.suppliersgridData);
  }

  protected supplierPageChange({ skip, take }: PageChangeEvent): void {
    this.supplierskip = skip;
    this.supplierPageSize = take;
    this.supplierCurrentPage = Math.floor(skip / take) + 1; // Calculate current page
    this.getSuppliersData(); // Load data for the current page
  }

  editMaster(data: any) {
    console.log(data);
    let masterData = data;
    this.modalRef = this.modalService.show(RpCreateMasterComponent, {
      initialState: { masterData },
      ...this.config,
    });
  }

  editSupplier(data: any) {
    console.log(data);
    let supplierData = data;
    this.modalRef = this.modalService.show(RpCreateSupplierComponent, {
      initialState: { supplierData },
      ...this.config,
    });
  }

  onSupplierFilter(value: any): void {
    // const inputValue = value;
    // this.searchedSupplierValue = value;
    this.supplierPageChange({ skip:0, take:this.supplierPageSize })
    this.getSuppliersData();
  }

  public onMasterFilter(value: any): void {
    this.currentPage = 1;
    this.masterPageChange({ skip:0, take:this.pageSize })
    this.getMastersData();
  }

  deleteMaster(dataItem: any) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        showCancelButton: true,
      })
      .then((result) => {
        console.log(result.value);
        if (result.value) {
          this.store.dispatch(deleteMaster({ id: dataItem.id }));
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            '',
            SweetAlertIcon.INFO
          );
        }
      });
  }

  deleteSupplier(dataItem: any) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        showCancelButton: true,
      })
      .then((result) => {
        console.log(result.value);
        if (result.value) {
          this.store.dispatch(deleteSupplier({ id: dataItem.id }));
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            '',
            SweetAlertIcon.INFO
          );
        }
      });
  }

  ngOnInit(): void {
    this.getAllConfigData();
    this.getMastersData();
    this.getSuppliersData();
  }

  getAllConfigData(): void {
    this.sharedService.getAllConfigData().subscribe((data) => {
      if (
        data.statusCode == 200 &&
        data.message == 'Config data retrieved successfully.'
      ) {
      }
    });
  }

  getMastersData() {
    this.store.dispatch(
      Getmaster({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedMasterValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
      })
    );
  }

  getSuppliersData() {
    this.store.dispatch(
      GetSuppliers({
        pageIndex: this.supplierCurrentPage,
        pageSize: this.supplierPageSize,
        searchTerm: this.searchedSupplierValue,
        filter: this.filterqueryString,
        orderBy: this.supplierOrderByQuery,
      })
    );
  }

  mastersexportAll() {
    this.getAllMastersData();
  }
  exportMaster(type: string) {
    if (type === 'Excel') {
      setTimeout(() => {
        this.masterhiddenGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.masterhiddenGrid?.saveAsPDF();
      }, 1000);
    }
    /*  if (this.mySelectionMasters.length == 0) {
       this.getAllMastersData();
     } else {
       if (type === 'Excel') {
         setTimeout(() => {
           this.masterhiddenGrid?.saveAsExcel();
         }, 1000);
       } else if (type === 'Pdf') {
         setTimeout(() => {
           this.masterhiddenGrid?.saveAsPDF();
         }, 1000);
       }
     } */
  }
  suppliersExportAll() {
    this.getAllSuppliersData();
  }
  exportSupplier(type: string) {
    if (type === 'Excel') {
      setTimeout(() => {
        this.SupplierhiddenGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.SupplierhiddenGrid?.saveAsPDF();
      }, 1000);
    }
    /*  if (this.mySelectionSupplier.length == 0) {
       this.getAllSuppliersData();
     } else {
       if (type === 'Excel') {
         setTimeout(() => {
           this.SupplierhiddenGrid?.saveAsExcel();
         }, 1000);
       } else if (type === 'Pdf') {
         setTimeout(() => {
           this.SupplierhiddenGrid?.saveAsPDF();
         }, 1000);
       }
     } */
  }

  getAllMastersData() {
    this.store.dispatch(
      GetAllmaster({
        pageIndex: 1,
        searchTerm: '',
        filter: '',
        orderBy: '',
        criteria: 'All',
      })
    );
  }

  getAllSuppliersData() {
    this.store.dispatch(
      GetAllSuppliers({
        pageIndex: 1,
        pageSize: undefined,
        searchTerm: '',
        filter: '',
        orderBy: '',
      })
    );
  }
  private exportSubscriptionMeters: Subscription = new Subscription();
  private exportSubscriptionSuppliers: Subscription = new Subscription();

  getStoreInfo() {
    let getMasters$ = this.store.pipe(select(getMasterResponse));
    let createMasterResponse$ = this.store.pipe(select(createMasterResponse));
    let getSuppliers$ = this.store.pipe(select(getSuppliersResponse));
    // console.log(this.sendOtpResponse$);
    this.exportSubscriptionSuppliers = this.store
      .pipe(select(getAllSuppliersResponse))
      .subscribe((res: any) => {
        if (res) {
          this.allSupplierList = [];
          if (res.items.length > 0) {
            this.allSupplierList = [...res.items];
            const transformedData = this.allSupplierList.map((item: any) => ({
              Type: item.master.name,
              Name: item.name,
            }));
            this.PdfexportService.downloadExcel(transformedData, 'supplier');
          } else {
            this.sharedService.showMessageDialog(
              'No data to export',
              '',
              SweetAlertIcon.INFO
            );
          }
        }
      });

    this.exportSubscriptionMeters = this.store
      .pipe(select(getAllMasterResponse))
      .subscribe((res: any) => {
        if (res) {
          this.allMastersList = [];
          if (res.items.length > 0) {
            this.allMastersList = [...res.items];
            const transformedData = this.allMastersList.map((item: any) => ({
              Type: item.type.name,
              Name: item.name,
            }));
            this.PdfexportService.downloadExcel(transformedData, 'Masters');
          } else {
            this.sharedService.showMessageDialog(
              'No data to export',
              '',
              SweetAlertIcon.ERROR
            );
          }
        }
      });

    getMasters$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.mastersList = [...res.items];
        this.pageSize = res.pageSize;
        this.totalMasterCount = res.totalCount;
        this.currentPage = res.pageIndex;
      }
      this.loadMasters();
      console.log('mastersList', res, this.mastersList);
    });

    createMasterResponse$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      this.getMastersData();
      console.log('createmaster', res);
    });

    getSuppliers$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.suppliersList = [...res.items];
        this.supplierPageSize = res.pageSize;
        this.totalSuppliersCount = res.totalCount;
      }
      this.loadSuppliers();
      console.log('mastersList', res, this.mastersList);
    });
  }

  addMaster() {
    this.modalRef = this.modalService.show(
      RpCreateMasterComponent,
      this.config
    );
  }
  ngOnDestroy() {
    this.store.dispatch(resetState());
    this.store.dispatch(resetSupplierState());
    this.allSupplierList = [];
    this.allMastersList = [];
    this.exportSubscriptionMeters.unsubscribe();
    this.exportSubscriptionSuppliers.unsubscribe(); // Or set it to [] if you prefer an empty array
  }
  addSuppliers() {
    this.modalRef = this.modalService.show(
      RpCreateSupplierComponent,
      this.config
    );
  }

  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.masterstate.filter = filter;
    console.log(this.masterstate);
    const queryString = this.sharedService.createQuery(this.masterstate);
    this.queryString = this.sharedService.createQuery(this.masterstate);
    this.getMastersData();
    console.log(queryString);
  }
  onSortChange(sort: SortDescriptor[], type: string): void {
    if (type == 'master') {
      this.masterstate.sort = sort;
      console.log(this.masterstate.sort);
      const orderByQuery = this.sharedService.createOrderByQuery(
        this.masterstate.sort
      );

      this.orderByQuery = orderByQuery;
      this.getMastersData();
    } else {
      this.supplierstate.sort = sort;
      console.log(this.masterstate.sort);
      const orderByQuery = this.sharedService.createOrderByQuery(
        this.supplierstate.sort
      );

      this.supplierOrderByQuery = orderByQuery;
      this.getSuppliersData();
    }
  }

  onSupplierFilterChange(filter: CompositeFilterDescriptor): void {
    this.supplierstate.filter = filter;
    console.log(this.supplierstate);
    const queryString = this.sharedService.createQuery(this.supplierstate);
    this.filterqueryString = this.sharedService.createQuery(this.supplierstate);
    this.getSuppliersData();
    console.log(queryString);
  }

  public onMasterPdfExport() {
    this.hiddenGrid?.saveAsPDF();
  }

  onSupplierPdfExport() {
    this.hiddenGrid?.saveAsPDF();
  }
  public isMasterRowSelected = (rowArg: any) => {
    return this.mySelectionMasters.some((row: any) => row.id === rowArg.id);
  };

  public isSupplierRowSelected = (rowArg: any) => {
    return this.mySelectionSupplier.some((row: any) => row.id === rowArg.id);
  };

  public selectionChanged(selection: any) {
    // Handle deselected rows
    selection.deselectedRows.forEach((row: any) => {
      const index = this.mySelectionMasters.findIndex(
        (selectedRow: any) => selectedRow.id === row.dataItem.id
      );
      if (index > -1) {
        this.mySelectionMasters.splice(index, 1);
      }
    });

    // Handle selected rows
    selection.selectedRows.forEach((row: any) => {
      if (!this.isMasterRowSelected(row.dataItem)) {
        this.mySelectionMasters.push(row.dataItem);
      }
    });

    console.log(this.mySelectionMasters);
  }

  supplierselectionChanged(selection: any) {
    // Handle deselected rows
    selection.deselectedRows.forEach((row: any) => {
      const index = this.mySelectionSupplier.findIndex(
        (selectedRow: any) => selectedRow.id === row.dataItem.id
      );
      if (index > -1) {
        this.mySelectionSupplier.splice(index, 1);
      }
    });

    // Handle selected rows
    selection.selectedRows.forEach((row: any) => {
      if (!this.isSupplierRowSelected(row.dataItem)) {
        this.mySelectionSupplier.push(row.dataItem);
      }
    });

    console.log(this.mySelectionSupplier);
  }
}

// @Injectable()
// export class ProductsService extends NorthwindService {
//     constructor(http: HttpClient) {
//         super(http, 'Products');
//     }

// }
