import { createAction, props } from '@ngrx/store';
import { Supplier } from './suppliers.models';

// Masters creation
export const GetSuppliers = createAction(
  '[Suppliers] getSuppliers',
  props<{
    pageIndex: number;
    pageSize: number;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getSuppliersSuccess = createAction(
  '[Suppliers] getSuppliers Success',
  props<{ getSuppliersResponse: any }>()
);
export const getSuppliersFailure = createAction(
  '[Suppliers] getSuppliers Failure',
  props<{ getSuppliersError: any }>()
);

// supplier creation
export const GetAllSuppliers = createAction(
  '[Suppliers] getAllSuppliers',
  props<{
    pageIndex: number;
    pageSize: number | undefined;
    searchTerm: string;
    filter?: string;
    orderBy?: string;
  }>()
);
export const getAllSuppliersSuccess = createAction(
  '[Suppliers] getAllSuppliers Success',
  props<{ getAllSuppliersResponse: any }>()
);
export const getAllSuppliersFailure = createAction(
  '[Suppliers] getAllSuppliers Failure',
  props<{ getAllSuppliersError: any }>()
);

// Create master
export const createSupplier = createAction(
  '[Suppliers] CreateSupplier',
  props<{ supplierData: Supplier }>()
);
export const CreateSupplierSuccess = createAction(
  '[Suppliers] CreateSupplier Success',
  props<{ createSupplierResponse: any }>()
);
export const CreateSupplierFailure = createAction(
  '[Suppliers] CreateSupplier Failure',
  props<{ createSupplierError: any }>()
);

// Update master
export const updateSupplier = createAction(
  '[Suppliers] updateSupplier',
  props<{ supplierData: Supplier; id: string }>()
);
export const updateSupplierSuccess = createAction(
  '[Suppliers] updateSupplier Success',
  props<{ updateSupplierResponse: any }>()
);
export const updateSupplierFailure = createAction(
  '[Suppliers] updateSupplier Failure',
  props<{ updateSupplierError: any }>()
);

//delete master
export const deleteSupplier = createAction(
  '[Suppliers] deleteSupplier',
  props<{ id: string }>()
);
export const deleteSupplierSuccess = createAction(
  '[Suppliers] deleteSupplier Success',
  props<{ deleteSupplierResponse: any }>()
);
export const deleteSupplierFailure = createAction(
  '[Suppliers] deleteSupplier Failure',
  props<{ deleteSupplierError: any }>()
);
export const resetSupplierState = createAction('[supplier ] Reset State');
