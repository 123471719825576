import { LoaderService } from './../../../core/services/loader.service';
import { Component, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/shared.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { createMaster, updateMaster } from '../../../store/Masters/masters.actions';
import { createSupplier, updateSupplier } from '../../../store/Suppliers/suppliers.actions';
import { Helper } from '../../../core/helpers/helper';
import { Validations } from '../../../shared/constants/validations';
@Component({
  selector: 'app-rp-create-supplier',
  templateUrl: './rp-create-supplier.component.html',
  styleUrl: './rp-create-supplier.component.scss'
})
export class RpCreateSupplierComponent {
  configData: any;
  createSupplierForm!: UntypedFormGroup;
  submitted: boolean = false;
  @Input() supplierData: any;
  Supplier_Validations = Validations.SupplierCreatePage;
  constructor(
    private router: Router,
    private store: Store,
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private formBuilder: UntypedFormBuilder
  ) // public activeModal: NgbActiveModal
  {
    this.getStoreInfo();
  }

  getStoreInfo() {}
  dismissModal(): void {
    this.modalService.hide();
  }

  CreateSupplier(type:string): void {
    this.submitted = true;
    if (this.createSupplierForm.valid) {
      this.loaderService.setLoading(true);
      // Process form data (send to backend, etc.)
      console.log(this.createSupplierForm.value);
      if(type=='Create'){
        this.store.dispatch(
          createSupplier({ supplierData: this.createSupplierForm.value })
        );
      }else if(type =='Update'){
        console.log(this.createSupplierForm.value);
        this.store.dispatch(
          updateSupplier({ supplierData: this.createSupplierForm.value, id:this.supplierData.id})
        );
      }
      this.modalService.hide();
    }
  }

  get f() {
    return this.createSupplierForm.controls;
  }

  ngOnInit(): void {
    this.createSupplierForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20),Helper.PatternValidator(/^[a-zA-Z0-9\s\-]*$/)]],
      masterId: [null, Validators.required],
    });
    this.getAllConfigData();
  }

  getAllConfigData(): void {
    this.sharedService.getAllConfigData().subscribe((data) => {
      if (
        data.statusCode == 200 &&
        data.message == 'Config data retrieved successfully.'
      ) {
        this.configData = data;
        

        //update master binding data
        // const matchedType = this.configData?.data?.types.find(
        //   (type: any) => type.name === this.supplierData.type
        // );

        this.createSupplierForm.patchValue({
          name: this.supplierData?.name?this.supplierData.name:'',
          masterId: this.supplierData?.master?.id?this.supplierData.master.id:''
        });
        //
      }
      console.log('Types', this.configData.data.types);
    });
  }
}
