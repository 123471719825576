import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MastersRoutingModule } from './masters-routing.module';
import { RpMastersComponent } from './rp-masters/rp-masters.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgStepperModule } from 'angular-ng-stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { GridModule, PDFModule, ExcelModule, } from '@progress/kendo-angular-grid';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RpCreateMasterComponent } from './rp-create-master/rp-create-master.component';
import { SharedModule } from '../../shared/shared.module';
import { RpCreateSupplierComponent } from './rp-create-supplier/rp-create-supplier.component';
import { ComponentsModule } from '../../shared/components/components.module';


// import { DropzoneModule } from 'ngx-dropzone-wrapper';
// import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
// import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

// const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
//   // Change this to your upload POST address:
//   url: '',
//   maxFilesize: 50,
//   acceptedFiles: 'image/*'
// };

@NgModule({
  declarations: [
    RpMastersComponent, RpCreateMasterComponent, RpCreateSupplierComponent
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    NgStepperModule,
    MastersRoutingModule,
    CdkStepperModule,
    BsDatepickerModule,
    GridModule,
    PDFModule,
    ExcelModule,
    TextBoxModule,
    ModalModule,
    SharedModule, ComponentsModule,
    // DropzoneModule
  ],
  // providers: [
  //   {
  //   provide: DROPZONE_CONFIG,
  //   useValue: DEFAULT_DROPZONE_CONFIG
  // }]
})
export class MastersModule { }
